import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";

import "./App.css";
import { AppRoute } from "./route/AppRoute";

function App() {
  const theme = extendTheme({
    styles: {
      global: () => ({
        body: {
          // bg: useColorModeValue('gray.100', 'gray.700'),
        },
      }),
    },
  });
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <AppRoute></AppRoute>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
