import { Alert, AlertIcon, AlertTitle } from "@chakra-ui/react";

export default function AlertPop(props) {
  return (
    <Alert
      w={props.width}
      right="5.3%"
      zIndex="5"
      pos="fixed"
      borderRadius={10}
      className={props.className ? props.className : "hide"}
      status={props.status ? props.status : "error"}
    >
      <AlertIcon />
      <AlertTitle mr={2}>{props.title}</AlertTitle>
    </Alert>
  );
}
