import { Input, Text, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import * as XLSX from "xlsx";
// import "../../../assets/css/custom.css";
import "../../assets/css/custom.css";

function Excel({ handleExcel, handleBigData }) {
  const [items, setItems] = useState([]);
  const [fileName, setFileName] = useState();

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const workbook = XLSX.read(bufferArray, { type: "buffer" });
        // const worksheetname = workbook.SheetNames[0];
        const worksheet = workbook.Sheets["COMPILE"];
        const data = XLSX.utils.sheet_to_json(worksheet);

        resolve(data);
        console.log(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      handleBigData(d);
    });

    setFileName(file.name);
  };

  return (
    <div>
      <VStack>
        <input
          type={"file"}
          class="custom-file-input"
          id={"csvFileInput"}
          accept={".xlsx"}
          onChange={(e) => {
            const file = e.target.files[0];
            readExcel(file);
            handleExcel(true);
          }}
        />

        {handleExcel ? <Text align="center">File Chosen: {fileName}</Text> : ""}
      </VStack>
    </div>
  );
}

function WriteExcel({ data }) {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Responses");
  XLSX.writeFile(wb, "sampleData.export.xlsx", { compression: true });
}

export { Excel, WriteExcel };
