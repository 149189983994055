import React, { useEffect } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import CRUDFunction from "../../../functions/CRUDFunction";
import Calendar from "../../../components/calendar/calendar";
import { URL_LOCAL, URL_STAGING } from "../../../constant";

export default function CalendarAdmin() {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const res = await CRUDFunction.get(`${URL_STAGING}calendar`);
    if (res) {
      setData(res);
    }
  };

  return (
    <>
      <Calendar data={data} label={true} />
    </>
  );
}
