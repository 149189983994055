import { Box, Center, Heading, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";

function NotFoundPage() {
  return (
    // <h1>Not Found Error 404</h1>
    <Center mt="5%">
      <VStack>
        <Text fontSize="40px" fontWeight="medium">
          404 Not Found
        </Text>
        <Text fontSize="20px" fontWeight="semibold">
          The resource requested could not be found
        </Text>
      </VStack>
    </Center>
  );
}

export default NotFoundPage;
