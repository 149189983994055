import React, { useState } from "react";
import {
  Center,
  Select,
  Heading,
  Stack,
  Text,
  Container,
  RadioGroup,
  Radio,
  Button,
  FormLabel,
} from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";

export default function Register() {
  let nav = useNavigate();
  const { courseId } = useParams();

  const [date, setDate] = useState({
    date1: "",
    date2: "",
  });
  const [value, setValue] = React.useState("1");

  // useEffect(() => {
  //   const getTrain = async () => {
  //     const res = await getTrainingDetail();
  //     if (res) {
  //       setTrain(res);
  //     }
  //   };
  //   getTrain().catch(console.log);
  // });

  const [monthSelected, setBulan] = useState({});
  const infoDate = (e) => {
    setBulan(e.target.value);

    if (e.target.value === "August") {
      setDate({
        ...date,
        date1: "15 - 18 Aug 2022 (Mon-Thu)",
        date2: "22 - 25 Aug 2022 (Mon-Thu)",
      });
    }
    if (e.target.value === "September") {
      setDate({
        ...date,
        date1: "15 - 18 Sep 2022 (Mon-Thu)",
        date2: "22 - 25 Sep 2022 (Mon-Thu)",
      });
    }
    if (e.target.value === "October") {
      setDate({
        ...date,
        date1: "15 - 18 Oct 2022 (Mon-Thu)",
        date2: "22 - 25 Oct 2022 (Mon-Thu)",
      });
    }
  };

  // if(courseId === 'pche'){
  //     setCourse("PROFESSIONAL CERTIFICATE FOR HALAL EXECUTIVE (PCHE)");
  // }
  // if(courseId === 'pciha'){
  //     setCourse("PROFESSIONAL CERTIFICATE FOR INTERNAL HALAL AUDITORS (PCIHA)");
  // }
  // if(courseId === 'hap'){
  //     setCourse("PROGRAM KESEDARAN HALAL/HALAL AWARENESS PROGRAM");
  // }

  return (
    <>
      <Container
        maxW={{ base: "100%", sm: "100%", md: "60%", lg: "50%", xl: "40%" }}
        mt="5%"
      >
        <Center w="100%" borderRadius={15} boxShadow="2xl">
          <Stack w="100%" alignItems="center" color="grey.800" py={10}>
            <Heading fontSize={25} color="grey.900">
              REGISTRATION
            </Heading>
            <Center
              w="100%"
              bg="#205072"
              color="white"
              textAlign="center"
              p={5}
            >
              <Text fontWeight="bold">
                {courseId === "pche"
                  ? "PROFESSIONAL CERTIFICATE FOR HALAL EXECUTIVE (PCHE)"
                  : courseId === "pciha"
                  ? "PCIHA"
                  : courseId === "hap"
                  ? "HAP"
                  : ""}
              </Text>
            </Center>
            <FormLabel color="gray">Choose your session:</FormLabel>
            <Select
              placeholder="Select option"
              w="316px"
              textAlign="center"
              onChange={infoDate}
            >
              <option value="August">August</option>
              <option value="September">September</option>
              <option value="October">October</option>
            </Select>
            {date ? (
              <>
                <Text>Estimate Date:</Text>
                <Text>{date.date1}</Text>
                <Text>{date.date2}</Text>
              </>
            ) : (
              ""
            )}

            <Text pt={4}>
              <b>Malaysian: RM 2600</b>
            </Text>
            <Text>
              <b>Non-Malaysian: USD $ 1000</b>
            </Text>
            <FormLabel color="gray" pt={18}>
              Choose your nationality:
            </FormLabel>
            <RadioGroup
              size="lg"
              onChange={setValue}
              value={value}
              colorScheme="green"
            >
              <Stack direction="row" mb={10}>
                <Radio value="1">Malaysian</Radio>
                <Radio value="2">Non-Malaysian</Radio>
              </Stack>
            </RadioGroup>
            <Button
              className="btn"
              bg="#33945f"
              color="white"
              w="60%"
              onClick={() => {
                nav("../enrol/" + value, { replace: true });
              }}
            >
              Next
            </Button>
          </Stack>
        </Center>
      </Container>
    </>
  );
}
