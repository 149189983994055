import React from "react";
import { Container, Box, Center, Heading, Text } from "@chakra-ui/react";
import { FiCheckCircle } from "react-icons/fi";
import { useLocation } from "react-router-dom";

function AttendanceSuccess() {
  const { state } = useLocation();

  console.log(state);
  return (
    <div>
      <Container mt="100px">
        <Box
          p="10%"
          boxShadow="0 0 10px 0 rgba(0, 0, 0, 0.2)"
          borderRadius="8px"
        >
          <Center flexDirection="column">
            <FiCheckCircle
              size="100px"
              color="#468c32"
              style={{
                marginTop: "10px",
                verticalAlign: "middle",
                lineHeight: "50px",
              }}
            />
            <Heading fontSize={{ md: "24px", base: "20px" }} mt="10%">
              Successful
            </Heading>
            <Text
              fontSize={{ md: "14px", base: "12px" }}
              mt="10%"
              textAlign="center"
            >
              You have successfully punch your attendance to {state.name}
            </Text>
            <Text mt="5">You may close this page.</Text>
          </Center>
        </Box>
      </Container>
    </div>
  );
}

export { AttendanceSuccess };
